import { FC } from 'react';
import LForm from '../../components/loginForm/lgnForm';
import './Login.css';
import LOGO from '../../assets/images/nav.png';

const Login: FC = () => {
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center loginPage">
        <img src={LOGO} className="login-landing" alt="React Bootstrap logo" />
        <span className="text-white ms-4"> Retail services department </span>
        <div className="formDiv">
          <LForm />
        </div>
      </div>
    </>
  );
};

export default Login;
